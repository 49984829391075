import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
    ASSET_MANAGER__MY_LIBRARY__INIT,
    ASSET_MANAGER__UNSPLASH_LIBRARY__INIT,
    ASSET_MANAGER__AUDIO_LIBRARY__INIT,
    ASSET_MANAGER__MANAGER_SET_TAB,
    ASSET_MANAGER__LIBRARY__RESET_LIBRARY,
    ASSET_MANAGER__PIXABAY_LIBRARY__INIT,
} from 'store/actions'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'

import { isBlockedPixabay } from 'utils/common'

import Checkbox from '../Form/Checkbox/Checkbox'

import Menu from './components/Menu/Menu'
import UploadMedia from './components/UploadMedia'
import UploadBar from './components/UploadBar'
import VoiceRecorder from './components/VoiceRecorder/VoiceRecorder'
import MyLibrary from './views/MyLibrary/MyLibrary'
import UnsplashLibrary from './views/UnsplashLibrary/UnsplashLibrary'
import PixabayLibrary from './views/PixabayLibrary/PixabayLibrary'
import AudioLibrary from './views/AudioLibrary/AudioLibrary'
import FreeAudioFiles from './views/FreeAudioFiles/FreeAudioFiles'
import Loader from './components/Loader/Loader'

import { ASSET_TABS } from './utils/constants'

import './AssetManager.scss'

const AssetManager = ({ isOpen, onPlaceItem, onClose, settings = {} }) => {
    const { t } = useTranslation()
    const [isReady, setIsReady] = useState(false)
    const { managerTab, library } = useSelector(state => state.assetManager)
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [isOpenedVoiceRecorder, setIsOpenedVoiceRecorder] = useState(false)
    const [isCompressible, setIsCompressible] = useState(true)

    useEffect(() => {
        setIsReady(true)
    }, [])

    const placeItemAndClose = (...args) => {
        onPlaceItem(...args)
        onClose()
    }

    const onPlaceImage = image => {
        onPlaceItem({ src: image.normalUrl, id: image.id, author: image.authorInfo })
        onClose()
    }

    useEffect(() => {
        if (!isReady || !isOpen) return

        if (managerTab === ASSET_TABS.TAB_MY_LIBRARY) dispatch(ASSET_MANAGER__MY_LIBRARY__INIT())
        if (managerTab === ASSET_TABS.TAB_UNSPLASH_LIBRARY) dispatch(ASSET_MANAGER__UNSPLASH_LIBRARY__INIT())
        if (managerTab === ASSET_TABS.TAB_PIXABAY) dispatch(ASSET_MANAGER__PIXABAY_LIBRARY__INIT())
        if (managerTab === ASSET_TABS.TAB_AUDIO) dispatch(ASSET_MANAGER__AUDIO_LIBRARY__INIT())
        if (managerTab === ASSET_TABS.TAB_FREE_AUDIO) dispatch(ASSET_MANAGER__LIBRARY__RESET_LIBRARY())
    }, [managerTab, isOpen])

    const isAudio = managerTab === ASSET_TABS.TAB_AUDIO || managerTab === ASSET_TABS.TAB_FREE_AUDIO

    return (
        isOpen && (
            <Modal closeOnOverlayClick={false} closeOnEsc={false}>
                <Header title={isAudio ? t('Add audio') : t('Add image')} onClose={onClose} />
                <div className="asset-manager__main">
                    <div className="asset-manager__side-panel">
                        <Menu
                            className="asset-manager__menu"
                            currentTab={managerTab}
                            tabs={settings.managerTabs}
                            onSwitchTab={tab => dispatch(ASSET_MANAGER__MANAGER_SET_TAB(tab))}
                            t={t}
                        />
                        {isAudio && (
                            <div className="asset-manager__record-voice" onClick={() => setIsOpenedVoiceRecorder(true)}>
                                <span />
                                {t('Record voice')}
                            </div>
                        )}
                        <UploadMedia
                            className="asset-manager__upload-media"
                            variant="primary"
                            size="md"
                            isAudio={isAudio}
                            setIsLoading={setIsLoading}
                            isCompressible={isCompressible}
                            onPlace={onPlaceImage}
                        >
                            {isAudio ? t('Upload audio') : t('Upload image')}
                        </UploadMedia>
                        {!isAudio && (
                            <div className="asset-manager__upload-settings">
                                <ul className="asset-manager__upload-settings__list">
                                    <li className="asset-manager__upload-settings__list-item">
                                        <Checkbox
                                            label={t('Compress images')}
                                            value={isCompressible}
                                            onUpdate={v => setIsCompressible(v)}
                                        />
                                    </li>
                                </ul>
                                <p className="asset-manager__upload-settings__note">
                                    {t(
                                        'Resizing and compression save storage space and increase content loading speed.',
                                    )}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="asset-manager__tab-space">
                        {managerTab === ASSET_TABS.TAB_MY_LIBRARY && <MyLibrary onPlaceItem={placeItemAndClose} />}
                        {managerTab === ASSET_TABS.TAB_UNSPLASH_LIBRARY && (
                            <UnsplashLibrary
                                setIsLoading={setIsLoading}
                                onPlaceItem={placeItemAndClose}
                                isCompressible={isCompressible}
                                onPlace={onPlaceImage}
                            />
                        )}
                        {managerTab === ASSET_TABS.TAB_PIXABAY && !isBlockedPixabay() && (
                            <PixabayLibrary
                                setIsLoading={setIsLoading}
                                onPlaceItem={placeItemAndClose}
                                isCompressible={isCompressible}
                                onPlace={onPlaceImage}
                            />
                        )}
                        {managerTab === ASSET_TABS.TAB_AUDIO && <AudioLibrary onPlaceItem={placeItemAndClose} />}
                        {managerTab === ASSET_TABS.TAB_FREE_AUDIO && <FreeAudioFiles onPlaceItem={placeItemAndClose} />}
                    </div>
                    {isOpenedVoiceRecorder && <VoiceRecorder onClose={() => setIsOpenedVoiceRecorder(false)} />}
                </div>
                <UploadBar />
                {isLoading && <Loader />}
            </Modal>
        )
    )
}

export default AssetManager
